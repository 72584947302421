.MuiTableCell-head {
  color: #888fa0 !important;
}

.MuiTableHead-root {
  background-color: #fafbfc;
}

.MuiTableCell-head{
  font-weight: 600;
  font-size: 12px;
}
.MuiTableCell-root{
  font-weight: 500 !important;
  font-size: 13px !important;
}
.MuiTableCell-body {
  color: #434966 !important;
}
