
.call-img{
  height:51px;
  width:51px;
  cursor:pointer;
 }
button {
    background: #333e5a;
    color: #fff;
    font-size: 16px;
    padding: 0.4em;
    border-radius: 6px;
    border: 1px solid transparent;
  
  }
  button:hover {
    // filter: brightness(150%);
  }
.room {
    position: relative;
    flex: 1 1 auto;
    width:100%;
  }
  .room button {
    position: absolute;
    bottom: 0;
    right: 20px;
  }
  .room > h3 {
    text-align: center;
    font-weight: 300;
    margin-bottom: 1em;
  }

  .remote-waiting{
    height:80px;
    max-width: 400px;
    border-radius: 50px;
    margin: auto;
    position: absolute;
    top: 0; 
    left: 0; 
    bottom: 0; 
    right: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.2) 100%);
    padding:20px 70px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    .session-begin{
      color: #FFFFFF;
      font-family: "Manrope";
      font-size: 24px;
      font-weight: bold;
    }
    .session-note{
      color: #FFFFFF;
      font-family: Helvetica;
      font-size: 16px;
    }
  }
  
  .local-participant {
    text-align: center;
    margin-bottom: 2em;
    position: absolute;
    display: flex;
    justify-content: space-between;
    height:160px;
    width:100%;
    bottom:0;
    left:0;
    .caller-img{
       flex: 1 1 0;
       width: 0;
      .img-content{
        margin-left: 4%; 
        height:160px;
        width:205px;
      }

    }
    .call-setting{
       flex: 1 1 0;
       width: 0;
       display: flex;
       justify-content: center;
       align-items: center;
       .abs-cont{
         width:100px;
         display: flex;
         justify-content: center;
         align-items: center;
       .call-img{
         height:51px;
         width:51px;
         cursor:pointer;
        }
      }
        
    }
    .chat-icon{
       flex: 1 1 0;
       width: 0;
    }
  }
  .remote-participants {
    display: flex;
    position: absolute;
    width:100%;
    height:100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    .rm-parti{
      vertical-align: middle;
      position: relative;
      margin:0 auto;
      height:100%;
      width:45%;
     // background-image:url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTSmrcJPzCHX9yQkipeFsdsU1Umt6_pdruyNw&usqp=CAU");
      background-repeat:no-repeat;
      background-size:cover;
    }
  }

.participant {
    background: #333e5a;
    padding: 10px;
    border-radius: 6px;
    display: inline-block;
    margin-right: 10px;
  }
  .participant:last-child {
    margin-right: 0;
  }
  .participant h3 {
    text-align: center;
    padding-bottom: 0.5em;
    color: #fff;
  }
video {
    width: 100%;
    max-width: 800px;
    display: block;
    margin: 0 auto;
  }

  .video-top{
    padding: 44px;
    width: 80%;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    max-width: 710px;
    background-color: grey;
    opacity: 0.5;
    position: absolute;
  }
  